import React, { useState, useEffect } from "react";
import { Table } from "antd";

function ExportData({ data, columns, type }) {
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    for (let i = 0; i < data.length; i++) {}
  }, [data]);

  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="blue f-20 mb-10 mt-30">Vão ser exportados:</p>
      <p className="bold blue f-40 mt-10 mb-0">{data.length}</p>
      <p className="blue f-20 mt-0 mb-30">{type}</p>
      <Table columns={columns} dataSource={data} scroll={{ x: 1 }} />
    </div>
  );
}

export default ExportData;
