import { Layout, Row, Col, Spin } from "antd";
import imageLogo from "../../assets/images/moedas.svg";
import "./Loading.css";

export default function Loading() {
  return (
    <div className="loading_div">
      <img src={imageLogo} className="mb-20" style={{ maxWidth: 300 }} />
      <Spin spinning={true} />
    </div>
  );
}
