import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form, Avatar } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import endpoints from "../../utils/endpoints";
import DrawerExportTable from "../../components/Export/DrawerExportTable";

import "./Game.css";
import config from "../../utils/config";

export default function Game() {
  const [data, setData] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Medicação",
      dataIndex: "medication",
      filters: [
        {
          text: "Lokelma",
          value: "Lokelma",
        },
        {
          text: "Forxiga",
          value: "Forxiga",
        },
        {
          text: "Ondexxya",
          value: "Ondexxya",
        },
        {
          text: "Amiloidose",
          value: "Amiloidose",
        },
      ],
      onFilter: (value, record) => record.medication.indexOf(value) === 0,
    },
    {
      title: "Número de ordem",
      dataIndex: "order_nr",
    },
  ];

  useEffect(() => {
    handleGetUsers();
  }, []);

  function handleGetUsers() {
    setIsLoading(true);
    axios
      .get(endpoints.game.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      let auxAnswers = JSON.parse(data[i].answers);
      console.log(auxAnswers);
      let auxObj = {
        key: data[i].id,
        id: data[i].id,
        medication: data[i].medication,
        order_nr: data[i].order_nr,
        answers: data[i].answers,
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = data.filter(
      (item) =>
        item.email.includes(searchString) ||
        item.name.toLowerCase().includes(searchString)
    );
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  function handlePrepareDataToExport() {
    let aux = [];
    let auxTableData =
      filteredTableData.length > 0 ? filteredTableData : tableData;
    for (let i = 0; i < auxTableData.length; i++) {
      console.log(auxTableData[i].answers);
      let answers = JSON.parse(auxTableData[i].answers);
      let objAnswers = {};
      for (let z = 0; z < answers.length; z++) {
        objAnswers = {
          ...objAnswers,
          [answers[z].question]: answers[z].answer,
        };
      }

      delete auxTableData[i].full_data;

      aux.push({
        ...auxTableData[i],
        ...objAnswers,
      });
    }

    setDataToExport(aux);
    setIsOpenExportDrawer(true);
  }

  function expandedRowRender(e) {
    console.log(e);
    let auxAnswers = JSON.parse(e.answers);
    console.log(auxAnswers);
    let columnsExpanded = [
      {
        title: "Pergunta",
        dataIndex: "question",
        key: "question",
      },
      {
        title: "Resposta",
        dataIndex: "answer",
        key: "answer",
      },
    ];

    let dataExpanded = [];
    for (let i = 0; i < auxAnswers.length; i++) {
      dataExpanded.push({
        key: i,
        question: (
          <p dangerouslySetInnerHTML={{ __html: auxAnswers[i].question }} />
        ),
        answer: (
          <p dangerouslySetInnerHTML={{ __html: auxAnswers[i].answer }} />
        ),
      });
    }

    return (
      <Table
        className="expanded_table"
        columns={columnsExpanded}
        dataSource={dataExpanded}
        pagination={false}
      />
    );
  }

  function handleChangeTable(pagination, filters, sorter, extra) {
    setFilteredTableData(extra.currentDataSource);
  }

  return (
    <div className="dashboard_page">
      <Row>
        <DrawerExportTable
          open={isOpenExportDrawer}
          close={handleCloseExport}
          data={dataToExport}
        />
        <Col span={24}>
          <Row className="mb-20">
            <Col span={6} className="d-flex ai-center">
              <h2 className="mt-0 mb-0">Game</h2>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Form
                  onValuesChange={handleSearch}
                  style={{ maxWidth: 400, borderRadius: 0 }}
                >
                  <Form.Item name="search" className="mb-0 mr-10">
                    <Input
                      size="large"
                      className=""
                      style={{ maxWidth: 400, borderRadius: 0 }}
                      placeholder="Search..."
                    />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetUsers}>
                  <ReloadOutlined />
                </Button>
                <Button
                  className="mr-10"
                  size="large"
                  onClick={handlePrepareDataToExport}
                >
                  Export
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table
                onChange={handleChangeTable}
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
                expandable={{
                  expandedRowRender,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
