import React, { useState, useEffect } from "react";
import { Button, Drawer, Steps } from "antd";
import { useForm } from "antd/es/form/Form";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ChooseColumns from "./ChooseColumns";
import ExportData from "./ExportData";
import dayjs from "dayjs";

function DrawerExportTable({ open, close, data, type }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [columnsToExport, setColumnsToExport] = useState([]);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let aux = [];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      if (data[i].full_data) {
        if (type === "Utilizadores") {
          data[i].full_data.birth_date = data[i].full_data.birth_date
            ? dayjs(data[i].full_data.birth_date).format("YYYY-MM-DD")
            : null;
          data[i].full_data.payment_date = data[i].full_data.payment_date
            ? dayjs(data[i].full_data.payment_date).format("YYYY-MM-DD")
            : null;
          data[i].full_data.created_at = data[i].full_data.created_at
            ? dayjs(data[i].full_data.created_at).format("YYYY-MM-DD")
            : null;
          data[i].full_data.boarding_year =
            data[i].full_data.boarding_year &&
            data[i].full_data.boarding_year !== "0000"
              ? dayjs(data[i].full_data.boarding_year).format("YYYY")
              : null;
          delete data[i].full_data.is_admin;
          delete data[i].full_data.is_viewer;
          delete data[i].full_data.password;
          delete data[i].full_data.modified_at;
        }
        aux.push(data[i].full_data);
      } else {
        delete data[i].actions;
        delete data[i].key;
        aux.push(data[i]);
      }
    }

    setDataToExport(aux);
  }, [data]);

  const [form] = useForm();

  function handleClose() {
    form.resetFields();
    close();
    setCurrent(0);
  }

  function handleExport() {
    let fileName = `${dayjs().format("YYYY-MM-DD")}_${dayjs().format(
      "HHmmss"
    )}_SaudeflixCoin.xlsx`;
    let exportData = [];

    const headers = columnsToExport.map((column) => column.title);
    exportData.push(headers);

    dataToExport.forEach((row) => {
      let rowData = columnsToExport.map((column) => {
        let value = row[column.dataIndex || column.key];
        if (
          typeof value === "object" &&
          value?.props &&
          value.props?.children
        ) {
          value = value?.props?.children;
        }
        return value;
      });
      exportData.push(rowData);
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(file, fileName);
    close();
    setCurrent(0);
  }

  function handleChooseColumns(e) {
    let auxColumns = e.columns;
    setColumnsToExport(auxColumns);
    setCurrent(1);
  }

  function handleChangeStep(e) {
    setCurrent(e);
  }

  return (
    <Drawer
      className="drawer-banner"
      key={`drawer-create-banner`}
      title="Exportar"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          {current === 0 && (
            <Button type="primary" onClick={form.submit}>
              Seguinte
            </Button>
          )}
          {current === 1 && (
            <>
              <Button type="primary mr-10" onClick={() => setCurrent(0)}>
                Anterior
              </Button>
              <Button type="primary" onClick={handleExport}>
                Exportar
              </Button>
            </>
          )}
        </>
      }
    >
      <Steps
        current={current}
        onChange={handleChangeStep}
        items={[
          {
            title: "Escolher colunas",
          },
          {
            title: "Exportar dados",
          },
        ]}
      />
      {current === 0 && (
        <ChooseColumns
          form={form}
          handleSubmit={handleChooseColumns}
          data={dataToExport}
          type={type}
        />
      )}
      {current === 1 && (
        <ExportData data={dataToExport} columns={columnsToExport} type={type} />
      )}
    </Drawer>
  );
}

export default DrawerExportTable;
