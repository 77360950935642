const endpoints = {
  auth: {
    login: "auth/login",
    verifyUser: "auth/verifyUser",
    verifyToken: "auth/verifyToken",
    renewToken: "auth/renewToken",
  },
  form: {
    read: "form/read",
  },
  game: {
    read: "game/read",
  },
  user: {
    read: "user/read",
    create: "user/create",
    update: "user/update",
    delete: "user/delete",
  },
};

export default endpoints;
