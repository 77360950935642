import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfigProvider, Spin } from "antd";
import axios from "axios";

import Form from "./pages/Form/Form";
import Game from "./pages/Game/Game.js";
import Login from "./pages/Login/Login";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Main from "./layout/Main/Main";

import api from "./utils/api.js";

import "./assets/styles/global.css";
import "./index.css";
import "./App.css";
import Users from "./pages/Users/Users";

api.axiosCreate();

function App() {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#830351",
          fontFamily: "Raleway",
        },
      }}
    >
      <div
        className={`App ${
          window.location.pathname.includes("/admin") ? "admin" : ""
        }`}
      >
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<Main handleLogout={handleLogout} />}>
            <Route
              index
              exact
              path="/"
              element={<Navigate to={`/form`} replace />}
            />
            <Route
              exact
              path="/login"
              element={<Navigate to={`/form`} replace />}
            />
            <Route exact path="/form" element={<Form />} />
            <Route exact path="/game" element={<Game />} />
            <Route exact path="/users" element={<Users />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
