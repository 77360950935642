import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form, Avatar } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import endpoints from "../../utils/endpoints";
import DrawerExportTable from "../../components/Export/DrawerExportTable";

import "./Form.css";

export default function FormTable() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Primeiro nome",
      dataIndex: "first_name",
    },
    {
      title: "Último nome",
      dataIndex: "last_name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "País de trabalho",
      dataIndex: "country",
    },
    {
      title: "Código postal",
      dataIndex: "postal_code",
    },
    {
      title: "Cidade de trabalho",
      dataIndex: "city",
    },
    {
      title: "Tipo de profissional",
      dataIndex: "profession",
    },
    {
      title: "Especialidade",
      dataIndex: "specialty",
    },
    {
      title: "Número de ordem",
      dataIndex: "order_nr",
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.form.read)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        key: data[i].id,
        id: data[i].id,
        first_name: data[i].first_name,
        last_name: data[i].last_name,
        email: data[i].email,
        country: data[i].country,
        postal_code: data[i].postal_code,
        city: data[i].city,
        profession: data[i].profession,
        specialty: data[i].specialty,
        order_nr: data[i].order_nr,
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = data.filter(
      (item) =>
        item.email.includes(searchString) ||
        item.name.toLowerCase().includes(searchString)
    );
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  return (
    <div className="dashboard_page">
      <Row>
        <DrawerExportTable
          open={isOpenExportDrawer}
          close={handleCloseExport}
          data={tableData}
        />
        <Col span={24}>
          <Row className="mb-20">
            <Col span={6} className="d-flex ai-center">
              <h2 className="mt-0 mb-0">Formulário de registo</h2>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Form
                  onValuesChange={handleSearch}
                  style={{ maxWidth: 400, borderRadius: 0 }}
                >
                  <Form.Item name="search" className="mb-0 mr-10">
                    <Input
                      size="large"
                      className=""
                      style={{ maxWidth: 400, borderRadius: 0 }}
                      placeholder="Search..."
                    />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetData}>
                  <ReloadOutlined />
                </Button>
                <Button
                  className="mr-10"
                  size="large"
                  onClick={handleOpenExport}
                >
                  Export
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
