import React, { useState } from "react";
import { Button, Form, Drawer, message, Input, Row, Col } from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";

import endpoints from "../../utils/endpoints";
import config from "../../utils/config";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = useForm();

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.user.update, {
        data: values,
      })
      .then((res) => {
        if (res.user_registered) {
          setTimeout(() => {
            setIsButtonLoading(false);
            handleClose();
            form.resetFields();
          }, 1000);
        } else {
          console.log(res);
          message.error({ content: res.message });
          setIsButtonLoading(false);
        }
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  function handleGeneratePassword() {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 8;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    console.log(password);
    form.setFieldValue("password", password);
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Create user"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button
          size="large"
          disabled={isButtonLoading}
          className="mr-10"
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          size="large"
          loading={isButtonLoading}
          type="primary"
          onClick={form.submit}
        >
          Create
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Row gutter={[24]} className="mb-24">
          <Col span={18}>
            <Form.Item
              className="mb-0"
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
          </Col>
          <Col span={6} className="d-flex jc-end ai-end">
            <Button
              size="large"
              className="minh-38 maxh-38 f-14"
              type="primary"
              onClick={handleGeneratePassword}
            >
              Generate Password
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Create;
